<style lang="less" scoped>
#commoditylist {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-status {
    width: 150px;
    margin-right: 14px;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="commoditylist" id="commoditylist">
    <div class="tab-top">
      <table-title />
      <table-action alias="commoditylist" :hasSearch="false" @success="onSuccess">
        <a-select placeholder="请选择状态" class="select-status" slot="custom" @change="selectOrderStatus">
          <a-select-option :key="0">全部</a-select-option>
          <a-select-option :key="1">待发布</a-select-option>
          <a-select-option :key="2">已发布</a-select-option>
          <a-select-option :key="3">已删除</a-select-option>
        </a-select>
      </table-action>
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" @click="onEdit(record.commodity_id)">修改</span>
        <a-divider v-show="record.commodity_status==1 || record.commodity_status==3" type="vertical" />
        <span class="model-action" v-show="record.commodity_status==1 || record.commodity_status==3" @click="onPush(record.commodity_id)">发布</span>
        <a-divider v-show="record.commodity_status!=3" type="vertical" />
        <table-delete v-show="record.commodity_status!=3" @comfirm="comfirmDelete(record.commodity_id)"></table-delete>
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchCommoditylist, updateCommodity } from "@/api/commodity";
const columns = [
  {
    title: "商品ID",
    dataIndex: "commodity_id",
  },
  {
    title: "商品名称",
    dataIndex: "commodity_name",
  },
  {
    title: "商品价格",
    dataIndex: "commodity_price",
  },
  {
    title: "商品状态",
    dataIndex: "commodity_status_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "CommodityList",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve)
  },
  data() {
    return {
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      selectStatus: undefined,
      loading: true
    };
  },
  created() {
    this.getCommoditylist();
  },
  methods: {
    async getCommoditylist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchCommoditylist({
          page_size: this.size,
          page: this.current,
          commodity_status: this.selectStatus,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.commodity_list.map((item, index) => {
          switch (item.commodity_status) {
            case 1:
              item.commodity_status_text = "待发布";   
              break;
            case 2:
              item.commodity_status_text = "已发布";   
              break;
            case 3:
              item.commodity_status_text = "已删除";   
              break;
            default:
              break;
          }
          item["key"] = item.commodity_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getCommoditylist();
    },
    onSuccess(e) {
      this.$router.push({ name: "commodityDetail" });
    },
    selectOrderStatus(e) {
      this.selectStatus = e;
      if (e == 0) {
        this.selectStatus = undefined;  
      }
      this.current = 1;
      this.getCommoditylist();
    },
    onEdit(commodityId) {
      this.$router.push({ name: "commodityDetail", query: { commodityId: commodityId }});
    },
    comfirmDelete(commodityId) {
      this.updateCommodityData(commodityId, 3);
    },
    onPush(commodityId) {
      this.updateCommodityData(commodityId, 2);
    },
    async updateCommodityData(commodityId, status) {
      this.loading = true;
      try {
        let params = {
          commodity_status: status,
          commodity_id: commodityId
        };
        await updateCommodity(params);
        if (status == 2) {
          this.$message.success("商品已发布"); 
        }else {
          this.$message.success("商品已删除");
        }
        this.loading = false;
        this.getCommoditylist()
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>
